import React from 'react';
import { Button, ChevronLink, Grid, Spacer, Text } from 'dss-ui-library';
import { AvailabilityResponseModalProps } from './types';
import { IconTextBox } from './Elements/IconTextBox';
import { replaceDynamicText } from '../../../../../utils/helpers';
import { ModalContentWrapper } from '@ncs-frontend-monorepo/availability';
import { ButtonRowBottom } from './Elements/ButtonRowBottom';
import { useOrderContext } from '../../../../OrderContext';

interface ModalContentPresaleProps extends AvailabilityResponseModalProps {
  hasAlternative: boolean;
}

export const ModalContentPresale: React.FC<ModalContentPresaleProps> = ({
  handleClose,
  handleRedirect,
  handleAddressReset,
  content,
  address,
  portfolio,
}) => {
  const { maxAvailableDownload, plannedAvailability } = useOrderContext();
  return (
    <ModalContentWrapper
      title="Verfügbare Produkte an deiner Adresse"
      address={address}
      resetAddress={handleAddressReset}
    >
      <Grid fullWidth>
        <Grid.Column>
          {content.modalText1 && (
            <>
              <IconTextBox
                icon={'rocket'}
                text={replaceDynamicText(content.modalText1, {
                  maxAvailableDownload,
                  presaleDate:
                    plannedAvailability?.plannedAvailabilityDateDescription,
                  presaleMaxDownload: plannedAvailability?.maxAvailableDownload,
                })}
              />
              <Spacer t={3} block />
            </>
          )}
          <Text appearance="t2_2" color="blue">
            {content.headline}
          </Text>
          <Spacer t={1} block />
        </Grid.Column>
        <ButtonRowBottom
          submitButton={
            <Button
              fullWidth
              color="blue"
              e2e="to-fiber"
              variant="primary"
              onClick={portfolio === 'CLASSIC' ? handleRedirect : handleClose}
            >
              Zu den Glasfaser-Tarifen
            </Button>
          }
          link={
            !!maxAvailableDownload && (
              <ChevronLink
                appearance="t5_2"
                e2e="to-classic"
                onClick={portfolio === 'CLASSIC' ? handleClose : handleRedirect}
              >
                Zu den DSL/Kabel-Tarifen
              </ChevronLink>
            )
          }
        />
      </Grid>
    </ModalContentWrapper>
  );
};
