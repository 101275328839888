import { MandantType } from '@ncs-frontend-monorepo/utils';
import { Portfolio } from '../../Context';
import { availablePlans } from '../Results';

export type AvailabilityType<Key> = {
  key: Key;
  portfolio?: Portfolio;
};

export const contentKeys = {
  FIBER_AVAILABLE: 'FIBER_AVAILABLE',
  CLASSIC_AVAILABLE: 'CLASSIC_AVAILABLE',
  CONTRARY_FIBER_AVAILABLE: 'CONTRARY_FIBER_AVAILABLE',
  CONTRARY_CLASSIC_AVAILABLE: 'CONTRARY_CLASSIC_AVAILABLE',

  // Young
  YOUNG_FIBER_AVAILABLE: 'YOUNG_FIBER_AVAILABLE',
  YOUNG_CLASSIC_AVAILABLE: 'YOUNG_CLASSIC_AVAILABLE',
  YOUNG_CONTRARY_FIBER_AVAILABLE: 'CONTRARY_YOUNG_FIBER_AVAILABLE',
  YOUNG_CONTRARY_CLASSIC_AVAILABLE: 'CONTRARY_YOUNG_CLASSIC_AVAILABLE',
  YOUNG_FIBER_AVAILABLE_PRESALE: 'YOUNG_FIBER_AVAILABLE_PRESALE',
  YOUNG_FIBER_PRESALE: 'YOUNG_FIBER_PRESALE',
  YOUNG_FIBER_AVAILABLE_GFAST_PRESALE: 'YOUNG_FIBER_AVAILABLE_GFAST_PRESALE',
  YOUNG_FIBER_GFAST_PRESALE: 'YOUNG_FIBER_GFAST_PRESALE',
  // ---

  // FTTH
  // mit alternative ohne Datum
  CLASSIC_AVAILABLE_AREA_PLANNED: 'CLASSIC_AVAILABLE_AREA_PLANNED',
  CLASSIC_AVAILABLE_PRE_MARKETING: 'CLASSIC_AVAILABLE_PRE_MARKETING',
  CLASSIC_AVAILABLE_UNDER_CONSTRUCTION: 'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION',

  // mit Alternative und Datum
  CLASSIC_AVAILABLE_AREA_PLANNED_DATE: 'CLASSIC_AVAILABLE_AREA_PLANNED_DATE',
  CLASSIC_AVAILABLE_PRE_MARKETING_DATE: 'CLASSIC_AVAILABLE_PRE_MARKETING_DATE',
  CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE:
    'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE',

  // ohne alternative ohne Datum
  AREA_PLANNED: 'AREA_PLANNED',
  PRE_MARKETING: 'PRE_MARKETING',
  UNDER_CONSTRUCTION: 'UNDER_CONSTRUCTION',

  // ohne alternative mit Datum
  AREA_PLANNED_DATE: 'AREA_PLANNED_DATE',
  PRE_MARKETING_DATE: 'PRE_MARKETING_DATE',
  UNDER_CONSTRUCTION_DATE: 'UNDER_CONSTRUCTION_DATE',

  // Presale
  FIBER_AVAILABLE_PRESALE: 'FIBER_AVAILABLE_PRESALE',
  FIBER_PRESALE: 'FIBER_PRESALE',
  FIBER_AVAILABLE_GFAST_PRESALE: 'FIBER_AVAILABLE_GFAST_PRESALE',
  FIBER_GFAST_PRESALE: 'FIBER_GFAST_PRESALE',
  // ---

  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ADSL: 'ADSL',
} as const;

export type ContentKey = (typeof contentKeys)[keyof typeof contentKeys];

export const availabilityType: { [key in ContentKey]: AvailabilityType<key> } =
  {
    CLASSIC_AVAILABLE: {
      key: 'CLASSIC_AVAILABLE',
      portfolio: 'CLASSIC',
    },
    FIBER_AVAILABLE: {
      key: 'FIBER_AVAILABLE',
      portfolio: 'FIBER',
    },
    YOUNG_CLASSIC_AVAILABLE: {
      key: 'YOUNG_CLASSIC_AVAILABLE',
      portfolio: 'CLASSIC',
    },
    YOUNG_FIBER_AVAILABLE: {
      key: 'YOUNG_FIBER_AVAILABLE',
      portfolio: 'FIBER',
    },
    CONTRARY_FIBER_AVAILABLE: {
      key: 'CONTRARY_FIBER_AVAILABLE',
      portfolio: 'FIBER',
    },
    CONTRARY_CLASSIC_AVAILABLE: {
      key: 'CONTRARY_CLASSIC_AVAILABLE',
      portfolio: 'CLASSIC',
    },
    CONTRARY_YOUNG_FIBER_AVAILABLE: {
      key: 'CONTRARY_YOUNG_FIBER_AVAILABLE',
      portfolio: 'FIBER',
    },
    CONTRARY_YOUNG_CLASSIC_AVAILABLE: {
      key: 'CONTRARY_YOUNG_CLASSIC_AVAILABLE',
      portfolio: 'CLASSIC',
    },

    // FTTH
    // alternative ohne Datum
    CLASSIC_AVAILABLE_AREA_PLANNED: {
      key: 'CLASSIC_AVAILABLE_AREA_PLANNED',
      portfolio: 'CLASSIC',
    },
    CLASSIC_AVAILABLE_PRE_MARKETING: {
      key: 'CLASSIC_AVAILABLE_PRE_MARKETING',
      portfolio: 'CLASSIC',
    },
    CLASSIC_AVAILABLE_UNDER_CONSTRUCTION: {
      key: 'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION',
      portfolio: 'CLASSIC',
    },
    // alternative mit Datum
    CLASSIC_AVAILABLE_AREA_PLANNED_DATE: {
      key: 'CLASSIC_AVAILABLE_AREA_PLANNED_DATE',
      portfolio: 'CLASSIC',
    },
    CLASSIC_AVAILABLE_PRE_MARKETING_DATE: {
      key: 'CLASSIC_AVAILABLE_PRE_MARKETING_DATE',
      portfolio: 'CLASSIC',
    },
    CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE: {
      key: 'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE',
      portfolio: 'CLASSIC',
    },
    // ohne alternative ohne Datum
    AREA_PLANNED: {
      key: 'AREA_PLANNED',
      portfolio: 'FIBER',
    },
    PRE_MARKETING: {
      key: 'PRE_MARKETING',
      portfolio: 'FIBER',
    },
    UNDER_CONSTRUCTION: {
      key: 'UNDER_CONSTRUCTION',
      portfolio: 'FIBER',
    },
    // ohne alternative mit Datum
    AREA_PLANNED_DATE: {
      key: 'AREA_PLANNED_DATE',
      portfolio: 'FIBER',
    },
    PRE_MARKETING_DATE: {
      key: 'PRE_MARKETING_DATE',
      portfolio: 'FIBER',
    },
    UNDER_CONSTRUCTION_DATE: {
      key: 'UNDER_CONSTRUCTION_DATE',
      portfolio: 'FIBER',
    },

    // Presale
    FIBER_AVAILABLE_PRESALE: {
      key: 'FIBER_AVAILABLE_PRESALE',
      portfolio: 'FIBER',
    },
    FIBER_PRESALE: {
      key: 'FIBER_PRESALE',
      portfolio: 'FIBER',
    },
    FIBER_AVAILABLE_GFAST_PRESALE: {
      key: 'FIBER_AVAILABLE_GFAST_PRESALE',
      portfolio: 'FIBER',
    },
    FIBER_GFAST_PRESALE: {
      key: 'FIBER_GFAST_PRESALE',
      portfolio: 'FIBER',
    },
    YOUNG_FIBER_AVAILABLE_PRESALE: {
      key: 'YOUNG_FIBER_AVAILABLE_PRESALE',
      portfolio: 'FIBER',
    },
    YOUNG_FIBER_PRESALE: {
      key: 'YOUNG_FIBER_PRESALE',
      portfolio: 'FIBER',
    },
    YOUNG_FIBER_AVAILABLE_GFAST_PRESALE: {
      key: 'YOUNG_FIBER_AVAILABLE_GFAST_PRESALE',
      portfolio: 'FIBER',
    },
    YOUNG_FIBER_GFAST_PRESALE: {
      key: 'YOUNG_FIBER_GFAST_PRESALE',
      portfolio: 'FIBER',
    },
    // ---
    NOT_AVAILABLE: {
      key: 'NOT_AVAILABLE',
    },
    ADSL: {
      key: 'ADSL',
    },
  };

export const createContentKey = (
  availabilityList: string[],
  portfolio?: Portfolio,
  mandantType?: MandantType,
): ContentKey => {
  const contentKey = availabilityList.join('_') as ContentKey;

  // fallback if contentKey does not exist or mandant is null
  if (!availabilityType[contentKey] || mandantType === null) {
    console.error(`${contentKey} is not a defined content key`);
    return 'NOT_AVAILABLE';
  }

  if (
    portfolio &&
    availabilityType[contentKey].portfolio !== portfolio &&
    availablePlans.includes(availabilityType[contentKey].key)
  ) {
    return ('CONTRARY_' + contentKey) as ContentKey;
  }

  return contentKey;
};
