import { Grid, RichText, Spacer, Text } from 'dss-ui-library';
import { AvailabilityResponseModalProps } from './types';
import { IconTextBox } from './Elements/IconTextBox';
import { replaceDynamicText } from '../../../../../utils/helpers';
import { Portfolio, useAvailabilityContext } from '../../../Context';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import { ButtonRowBottom } from './Elements/ButtonRowBottom';
import { useOrderContext } from '../../../../OrderContext';
import { getPortfolioUrl } from '../../../../../utils/plan';
import { ModalContentWrapper } from '@ncs-frontend-monorepo/availability';

interface ModalContentAvailableProps extends AvailabilityResponseModalProps {
  portfolio: Portfolio;
}

export const ModalContentAvailable: React.FC<ModalContentAvailableProps> = ({
  portfolio,
  content,
  address,
  handleAddressReset,
}) => {
  const { maxAvailableDownload } = useOrderContext();
  const { templateId } = useAvailabilityContext();

  const url = content?.buttonLink?.url
    ? content.buttonLink.url
    : getPortfolioUrl({
        portfolio,
        templateId,
      });

  return (
    <ModalContentWrapper
      title="Verfügbare Produkte an deiner Adresse"
      address={address}
      resetAddress={handleAddressReset}
    >
      <Grid fullWidth>
        <Grid.Column>
          {content.modalText1 && (
            <>
              <IconTextBox
                icon={portfolio === 'FIBER' ? 'rocket' : 'wlan'}
                text={replaceDynamicText(content.modalText1, {
                  maxAvailableDownload,
                })}
              />
              <Spacer t={3} block />
            </>
          )}
          <Text appearance="t2_2" color="blue">
            {content.headline}
          </Text>
          <Spacer t={1} block>
            <RichText text={content.modalText2} />
          </Spacer>
        </Grid.Column>
        <ButtonRowBottom
          submitButton={
            <ButtonLink btnFullwidth link={{ url }}>
              {portfolio === 'FIBER'
                ? 'Zu den Glasfaser-Tarifen'
                : 'Zu den DSL/Kabel-Tarifen'}
            </ButtonLink>
          }
        />
      </Grid>
    </ModalContentWrapper>
  );
};
