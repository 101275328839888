import React, { useCallback, useEffect, useState } from 'react';
import { AvailabilityCheckFormProps } from '../../../Views/Form/Form';
import { LoadingSpinner } from 'dss-ui-library';
import { getProducts } from '../../../../../utils/orderAPI';
import { pushAvailability } from '../../../../../utils/dataLayer/availability';
import {
  AvailabilityTypes,
  Portfolio,
  useAvailabilityContext,
} from '../../../Context';
import { useOrderContext } from '../../../../OrderContext';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../../interfaces/tracking';
import { getMaxDownloads, hasCableTV } from '../../../../../utils/promotion';
import dynamic from 'next/dynamic';
import { Product, ProductId } from '@ncs-frontend-monorepo/order';
import {
  NetTvType,
  FetchAddressReponse,
} from '@ncs-frontend-monorepo/availability';

interface TvCheckStandaloneProps {
  resetCheck(): void;
}

enum TvCheckResultType {
  Options = 'Options',
  Cable = 'Cable',
  Internet = 'Internet',
}

export interface TVCheckResultView {
  product: Product;
  address: FetchAddressReponse;
  onResetCheck(): void;
  onHandleClick(target: string): void;
  inkassoTypeValidFrom?: string;
  isCustomer?: boolean;
  availablePortfolio?: Portfolio;
  hasTvOptions?: boolean;
}

const OptionResult = dynamic(() =>
  import('./Views/TVOptions').then(({ TVOptions }) => TVOptions),
);

const CableTVResult = dynamic(() =>
  import('./Views/CableTV').then(({ CableTV }) => CableTV),
);

const TVInternetResult = dynamic(() =>
  import('./Views/TVInternet').then(({ TVInternet }) => TVInternet),
);

export const tvCheckStandaloneWordings = (
  siteName: string,
): Pick<AvailabilityCheckFormProps, 'headline' | 'subline'> => ({
  headline: 'Verfügbarkeitscheck',
  subline: `Sicher dir dein exklusives TV-Angebot - mit oder ohne Internet-Anschluss von ${siteName}.`,
});

export const TvCheckStandalone: React.FC<TvCheckStandaloneProps> = ({
  resetCheck,
}) => {
  const { formData, inkassoTypeValidFrom } = useOrderContext();
  const isCustomer = formData?.isCustomer ?? false;
  const { availabilityInfo, manualCheck, availablePortfolio } =
    useAvailabilityContext();
  const {
    address,
    promotions: { tvPromotions, presalePromotion, availablePromotions },
  } = availabilityInfo;
  const { maxAvailableDownload } = getMaxDownloads({
    availablePromotions,
    presalePromotion,
  });
  const [product, setProduct] = useState<Product>();
  const [tvCheckResultType, setTvCheckResultType] =
    useState<TvCheckResultType>();
  const { eventLinks, eventResult } = pushAvailability();

  useEffect(() => {
    let resultType: TvCheckResultType;
    let templateId: ProductId;
    switch (true) {
      case tvPromotions.includes(NetTvType.NET_TV_CABLE_OPTIONS):
        templateId = hasCableTV(tvPromotions)
          ? isCustomer
            ? ProductId.TV_CABLE_EXISTING_CUSTOMER
            : ProductId.TV_CABLE
          : ProductId.TV_CABLE_HD;
        resultType = hasCableTV(tvPromotions)
          ? TvCheckResultType.Cable
          : TvCheckResultType.Options;
        break;
      case tvPromotions.includes(NetTvType.NET_TV_CABLE):
      case tvPromotions.includes(NetTvType.NET_TV_FTTH):
        templateId = isCustomer
          ? ProductId.TV_CABLE_EXISTING_CUSTOMER
          : ProductId.TV_CABLE;
        resultType = TvCheckResultType.Cable;
        break;
      default:
        resultType = TvCheckResultType.Internet;
        templateId = ProductId.TV_INTERNET;
        break;
    }
    setTvCheckResultType(resultType);
    getProducts({ templateId }).then((productInfo) => {
      setProduct(productInfo);
    });
  }, [tvPromotions]);

  useEffect(() => {
    const presaleDescription = presalePromotion?.plannedAvailabilityDate || '';

    eventResult({
      event: DataLayerEvents.Result,
      status: AvailabilityStatus.Available,
      expansionStatus: availabilityInfo?.objectInformation?.status || null,
      checkSource: AvailabilityTypes.TvContent,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      zipCode: address.zipCode,
      maxDownload: maxAvailableDownload,
      presaleAvailability: presaleDescription !== '',
      ...(presaleDescription !== '' && {
        plannedAvailabilityDate: presaleDescription,
      }),
      tvPromotions,
    });
  }, [
    maxAvailableDownload,
    address.zipCode,
    availabilityInfo?.objectInformation?.status,
    manualCheck,
  ]);

  const handleClick = useCallback(
    (targetPage: string) => {
      eventLinks({
        event: DataLayerEvents.PlanLink,
        status: AvailabilityStatus.Available,
        zipCode: address.zipCode,
        checkSource: AvailabilityTypes.TvContent,
        maxDownload: maxAvailableDownload,
        targetPage,
      });
    },
    [address.zipCode, maxAvailableDownload],
  );

  return (
    <>
      {product ? (
        <>
          {tvCheckResultType === TvCheckResultType.Options && (
            <OptionResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={availabilityInfo?.address}
            />
          )}
          {tvCheckResultType === TvCheckResultType.Cable && (
            <CableTVResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={availabilityInfo?.address}
              isCustomer={isCustomer}
              inkassoTypeValidFrom={inkassoTypeValidFrom}
              availablePortfolio={availablePortfolio}
              hasTvOptions={tvPromotions.includes('NET_TV_CABLE_OPTIONS')}
            />
          )}
          {tvCheckResultType === TvCheckResultType.Internet && (
            <TVInternetResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={availabilityInfo?.address}
              isCustomer={isCustomer}
            />
          )}
        </>
      ) : (
        <LoadingSpinner theme="blue" />
      )}
    </>
  );
};
