import { Grid, IconPhone, Link, Spacer, Text } from 'dss-ui-library';
import { ChevronLink } from '@ncs-frontend-monorepo/content-library';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { PHONE_NA, PHONE_NC } from '../../../../constants/urls';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../interfaces/tracking';
import { pushAvailability } from '../../../../utils/dataLayer/availability';
import { isNC, Media } from '@ncs-frontend-monorepo/utils';
import { useOrderContext } from '../../../OrderContext';
import { useAvailabilityContext } from '../../Context';
import { AddressRow } from '../../Elements/AddressRow';

const FibreOpticExpansion = dynamic(() =>
  import('../../Elements/FibreOpticExpansion').then(
    ({ FibreOpticExpansion }) => FibreOpticExpansion,
  ),
);

interface ADSLFallbackProps {
  resetAddress: () => void;
}

export const ADSLFallback: React.FC<ADSLFallbackProps> = ({ resetAddress }) => {
  const { availabilityInfo, availabilityType, templateId, manualCheck } =
    useAvailabilityContext();
  const { maxAvailableDownload } = useOrderContext();
  const { eventLinks, eventResult } = pushAvailability();
  const { address, objectInformation, isFTTHPlanned } = availabilityInfo;
  const isNetCologne = isNC();

  const phoneNo = isNetCologne ? PHONE_NC : PHONE_NA;
  const contactLink = isNetCologne
    ? 'https://www.netcologne.de/privatkunden/kontakt'
    : 'https://www.netaachen.de/privatkunden/kontakt';
  const expansionLink = isNetCologne
    ? 'https://www.netcologne.de/ausbaugebiete'
    : 'https://www.netaachen.de/weisseflecken';

  useEffect(() => {
    eventResult({
      event: DataLayerEvents.Result,
      status: AvailabilityStatus.IndividualCheck,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      maxDownload: maxAvailableDownload,
      ...(templateId && { templateId }),
    });
  }, []);

  const handleClick = (
    targetPage: string,
    event: DataLayerEvents.ContactLink | DataLayerEvents.ExpansionLink,
  ) => {
    eventLinks({
      event,
      status: AvailabilityStatus.IndividualCheck,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      maxDownload: maxAvailableDownload,
      targetPage,
    });
  };

  return (
    <>
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          Verfügbare Produkte an deiner Adresse
        </Text>
      </Spacer>
      <AddressRow address={address} onChangeAddressClick={resetAddress} />
      <Grid fullWidth>
        {isFTTHPlanned && (
          <Spacer b={3} block>
            <FibreOpticExpansion
              buttonColor="red"
              displayStyle="nextto"
              objectInformation={objectInformation}
            />
          </Spacer>
        )}
        <Text e2e="adsl-fallback-info-text" appearance="t4">
          An deiner Adresse kannst du mit bis zu{' '}
          <strong>{maxAvailableDownload} Mbit/s</strong> im Netz surfen. Die
          Buchung ist nur über unsere Hotline möglich. Ruf uns an, wir beraten
          dich gerne.
        </Text>
        <Spacer t={3} block />
        <Grid.Row>
          <Grid.Column s={6} vEnd hEndS>
            <Link
              appearance="t4_2"
              url={`tel://${phoneNo}`}
              e2e="call-contact-hotline"
              icon={<IconPhone color="blue" width={24} height={24} />}
              onClick={() => handleClick(phoneNo, DataLayerEvents.ContactLink)}
            >
              {isNetCologne ? '0221 2222 - 800' : '0800 2222 - 333'}
            </Link>
            <Spacer t={1}>
              <Text appearance="t5" block>
                Wir sind rund um die Uhr für dich da
              </Text>
              <Spacer t={1} block />
              <ChevronLink
                appearance="t5"
                url={contactLink}
                e2e="to-contact-page"
                indent
                onClick={() =>
                  handleClick(contactLink, DataLayerEvents.ContactLink)
                }
              >
                Lass dich anrufen
              </ChevronLink>
            </Spacer>
          </Grid.Column>
          {!isFTTHPlanned && (
            <Grid.Column s={6} vEnd hEndS>
              <Media lessThan="s">
                <Spacer t={3} block />
              </Media>
              <Text appearance="t5" block>
                Mehr zur Verfügbarkeit und Glasfaser für die Region findest du
                hier:
              </Text>
              <Spacer t={1} block />
              <ChevronLink
                appearance="t5"
                url={expansionLink}
                e2e="to-expansion-areas"
                indent
                onClick={() =>
                  handleClick(expansionLink, DataLayerEvents.ExpansionLink)
                }
              >
                Anschluss- und Ausbaugebiete
              </ChevronLink>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
};
