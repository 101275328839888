import { Spacer } from 'dss-ui-library';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { MODULE_SPACING } from '../../constants';
import { CombinedContentType, CustomContentType } from '../../interfaces';
import { getElementId } from '../../utils/helpers';
import { ProductProvider } from '../ProductContext';
import { StaticContent } from '../StaticContent';

const PlanDetail = dynamic(() => import('../../components/PlanDetail'));
const PlanColumns = dynamic(() => import('../../components/PlanColumns'));
const PlanRows = dynamic(() => import('../../components/PlanRows'));
const TooltipImage = dynamic(() => import('../../components/TooltipImage/'));
const ProductComparison = dynamic(
  () => import('../../components/ProductComparison'),
);
const DynamicContent = dynamic(() =>
  import('@ncs-frontend-monorepo/content-library').then(
    ({ DynamicContent }) => DynamicContent,
  ),
);

const ExpansionCheck = dynamic(() =>
  import('../../components/ExpansionCheck').then(
    ({ ExpansionCheck }) => ExpansionCheck,
  ),
);

export interface OrderPageContentProps {
  content: CombinedContentType[];
  spacerSize?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

const CombinedContent: FC<OrderPageContentProps> = ({
  content,
  spacerSize = MODULE_SPACING,
}) => {
  return (
    <>
      {content.map((c: CombinedContentType, index: number) => {
        const withTopSpacing = index === 0;
        const spacerProps = {
          id: getElementId(c.id),
          key: c.id + c.component,
          t: withTopSpacing ? spacerSize : 0,
          b: spacerSize,
          block: true,
        };
        switch (c.component) {
          case CustomContentType.PLAN_DETAIL:
            return (
              <Spacer {...spacerProps}>
                <ProductProvider>
                  <PlanDetail {...c.props} />
                </ProductProvider>
              </Spacer>
            );
          case CustomContentType.PLAN_COLUMNS:
            return (
              <Spacer {...spacerProps}>
                <PlanColumns {...c.props} />
              </Spacer>
            );
          case CustomContentType.PLAN_ROWS:
            return (
              <Spacer {...spacerProps}>
                <PlanRows {...c.props} />
              </Spacer>
            );
          case CustomContentType.PRODUCT_COMPARISON:
            return (
              <Spacer {...spacerProps}>
                <ProductComparison {...c.props} />
              </Spacer>
            );
          case CustomContentType.TEMPLATE_ENGINE:
            return null;
          case CustomContentType.EXPANSION_CHECK:
            return (
              <Spacer {...spacerProps} t={index === 0 ? 0 : spacerSize}>
                <ExpansionCheck {...c.props} />
              </Spacer>
            );
          case CustomContentType.TOOLTIP_IMAGE:
            return (
              <Spacer {...spacerProps}>
                <TooltipImage {...c.props} />
              </Spacer>
            );
          case CustomContentType.STATIC_CONTENT:
            return (
              <Spacer {...spacerProps}>
                <StaticContent content={c.props} />
              </Spacer>
            );
          default:
            return (
              <DynamicContent
                key={c.id}
                content={c}
                withTopSpacing={withTopSpacing}
                spacerSize={spacerSize}
                getElementId={getElementId}
              />
            );
        }
      })}
    </>
  );
};

export default CombinedContent;
