import { useEffect, useState } from 'react';
import { OrderState, useOrderContext } from '../components/OrderContext';
import { isBandwidthAvailable } from '../utils/ftthPreMarketing';
import { Portfolio } from '../components/AvailabilityCheck/Context';
import {
  hasCableOptions,
  hasCableTV,
  isDisabledCableTVGroup,
} from '../utils/promotion';
import {
  ProductId,
  TemplateGroup,
  Product,
  ProductGroups,
} from '@ncs-frontend-monorepo/order';
import { CABLE_TV_COMBI_GROUPS } from '../constants/tv';
import {
  FTTHPresalesInformation,
  NetTvType,
} from '@ncs-frontend-monorepo/availability';

export interface PlanState {
  isDisabled: boolean;
  isPresale: boolean;
  isPlanStateChecked: boolean;
  portfolioMismatch?: boolean;
  availablePortfolio?: Portfolio;
  hasNetTVInternet: boolean;
}

const defaultPlanState: PlanState = {
  isDisabled: false,
  isPresale: false,
  isPlanStateChecked: false,
  portfolioMismatch: false,
  availablePortfolio: 'FIBER',
  hasNetTVInternet: false,
};

const getPlanState = ({
  plan,
  plannedAvailability,
  maxAvailableDownload,
  fallbackPlanState,
  promotions,
  portfolio,
  availableTv,
}: {
  plan: Product;
  plannedAvailability: OrderState['plannedAvailability'];
  maxAvailableDownload: number;
  fallbackPlanState: PlanState;
  availableTv: OrderState['availableTv'];
  promotions: OrderState['promotions'];
  portfolio?: Portfolio;
}) => {
  const { internet, group } = plan;
  const hasTVGroup = CABLE_TV_COMBI_GROUPS.includes(group);

  if (maxAvailableDownload === null && plannedAvailability === null) {
    return fallbackPlanState;
  } else {
    const plannedMaxAvailableDownload =
      plannedAvailability?.maxAvailableDownload || 0;

    const availablePromotion = promotions
      ?.sort((a, b) => (a.maxDownload < b.maxDownload ? -1 : 1))
      ?.find((promotion) => promotion.maxDownload >= internet?.download);

    const hasPlannedDownload =
      internet?.download >
      Math.max(maxAvailableDownload, plannedMaxAvailableDownload);

    const isPortfolioMismatch =
      portfolio && availablePromotion?.portfolio !== portfolio;

    // TODO: (https://jira.netcologne.intern/browse/WEB-7334)
    const hasMismatchingTV =
      // is tv & internet available
      hasTVGroup && maxAvailableDownload
        ? // does the portfolio match?
          isPortfolioMismatch
          ? // no => mismatch, except the template group is tv internet
            group !== TemplateGroup.TV_INTERNET
          : // yes => mismmatch, if the template group is cable and thats not available
            isDisabledCableTVGroup({ templateGroup: group, availableTv })
        : // mismmatch
          isPortfolioMismatch;

    return {
      isDisabled: hasPlannedDownload || hasMismatchingTV,
      isPresale:
        internet.download > maxAvailableDownload &&
        plannedMaxAvailableDownload >= internet.download,
      isPlanStateChecked: true,
      portfolioMismatch: portfolio
        ? availablePromotion && availablePromotion.portfolio !== portfolio
        : false,
      availablePortfolio: availablePromotion?.portfolio,
      hasNetTVInternet:
        availableTv?.includes(NetTvType.NET_TV_INTERNET) || false,
    };
  }
};

const getFTTHPlanState = ({
  plan,
  ftthPresalesInformation,
  fallbackPlanState,
  promotions,
  portfolio,
}: {
  plan: Product;
  ftthPresalesInformation: FTTHPresalesInformation;
  fallbackPlanState: PlanState;
  promotions: OrderState['promotions'];
  portfolio?: Portfolio;
}) => {
  const { internet } = plan;

  if (ftthPresalesInformation === null) {
    return fallbackPlanState;
  } else {
    // Prefer Presale
    const availablePromotion = promotions
      ?.sort((a, b) => (a.maxDownload > b.maxDownload ? -1 : 1))
      ?.find((promotion) => promotion.maxDownload >= internet.download);

    // return available portfolio
    return {
      isDisabled: !isBandwidthAvailable(
        internet.download,
        ftthPresalesInformation,
      ),
      isPresale: false,
      isPlanStateChecked: true,
      portfolioMismatch:
        !isBandwidthAvailable(internet.download, ftthPresalesInformation) &&
        availablePromotion &&
        availablePromotion.portfolio !== portfolio,
      availablePortfolio: availablePromotion?.portfolio,
      hasNetTVInternet: false,
    };
  }
};

const getTVPlanState = ({
  plan,
  availableTv,
  fallbackPlanState,
}: {
  plan: Product;
  availableTv: OrderState['availableTv'];
  fallbackPlanState: PlanState;
}) => {
  if (!availableTv) {
    return fallbackPlanState;
  }

  let isDisabled = false;

  if (plan.id === ProductId.TV_INTERNET) {
    isDisabled = !availableTv.includes(NetTvType.NET_TV_INTERNET);
  } else {
    isDisabled = plan.groups.includes(ProductGroups.TV_STANDALONE)
      ? !hasCableTV(availableTv)
      : !hasCableOptions(availableTv);
  }

  return {
    isDisabled,
    isPresale: false,
    isPlanStateChecked: true,
    portfolioMismatch: false,
    hasNetTVInternet: availableTv.includes(NetTvType.NET_TV_INTERNET),
  };
};

interface UsePlanStateParams {
  plan: Product;
  isFTTHPresale?: boolean;
  portfolio?: Portfolio;
}

export const usePlanState = ({
  plan,
  isFTTHPresale = false,
  portfolio,
}: UsePlanStateParams) => {
  const {
    maxAvailableDownload,
    plannedAvailability,
    ftthPresalesInformation,
    address,
    promotions,
    availableTv,
  } = useOrderContext();

  const [planState, setPlanState] = useState(defaultPlanState);

  useEffect(() => {
    if (!plan) {
      return;
    }
    if (
      plan.groups.includes(ProductGroups.TV_STANDALONE) ||
      plan.groups.includes(ProductGroups.TV_OPTION)
    ) {
      return setPlanState((currentPlanState) =>
        getTVPlanState({
          plan,
          availableTv,
          fallbackPlanState: address ? currentPlanState : defaultPlanState,
        }),
      );
    }

    setPlanState((currentPlanState) =>
      isFTTHPresale
        ? getFTTHPlanState({
            plan,
            ftthPresalesInformation,
            fallbackPlanState: address ? currentPlanState : defaultPlanState,
            promotions,
            portfolio,
          })
        : getPlanState({
            plan,
            plannedAvailability,
            maxAvailableDownload,
            fallbackPlanState: address ? currentPlanState : defaultPlanState,
            promotions,
            portfolio,
            availableTv,
          }),
    );
  }, [
    maxAvailableDownload,
    plannedAvailability,
    plan?.internet?.download,
    plan?.group,
    ftthPresalesInformation,
    address,
    isFTTHPresale,
    promotions,
    CABLE_TV_COMBI_GROUPS.includes(plan?.group),
    availableTv,
  ]);

  return planState;
};
