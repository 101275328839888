import { LoadingSpinner } from 'dss-ui-library';
import { Formik } from 'formik';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { ILeadContact } from '../../../interfaces/leadContact';
import { isNC } from '@ncs-frontend-monorepo/utils';
import {
  sendLeadContactForm,
  sendOptIn,
  sendOptOut,
} from '../../../utils/leadContactAPI';
import { checkAvailability } from '../../../utils/orderAPI';
import { initialData } from './formData/initialData';
import { LeadContactSchema } from './formData/schema';
import styles from './LeadContact.module.scss';
import { Opt } from './OptInOrOut';

const FormWrapper = dynamic(() =>
  import('./FormWrapper').then(({ FormWrapper }) => FormWrapper),
);

const OptInOrOut = dynamic(() =>
  import('./OptInOrOut').then(({ OptInOrOut }) => OptInOrOut),
);

const Success = dynamic(() =>
  import('./Success').then(({ Success }) => Success),
);

export interface LeadContactProps {
  errorContentSlug: string;
  optInContentSlug: string;
  optOutContentSlug: string;
  callbackURL: string;
  isCallbackPage?: string;
}

enum View {
  Loading = 'loading',
  Form = 'form',
  Error = 'error',
  Success = 'success',
  OptInOrOut = 'optInOrOut',
}

const LeadContactComponent: FC<LeadContactProps> = ({
  errorContentSlug,
  optInContentSlug,
  optOutContentSlug,
  callbackURL,
  isCallbackPage,
}) => {
  const { query, asPath, isReady } = useRouter();
  const { hash = '', mode = Opt.in } = query;
  const isOptCall = asPath.startsWith(callbackURL) && hash.length > 0;
  const [view, setView] = useState(isCallbackPage ? View.Loading : View.Form);

  useEffect(() => {
    if (isReady && isCallbackPage && isOptCall) {
      return setView(View.OptInOrOut);
    }

    setView(View.Form);
  }, [isReady]);

  const handleSubmit = async (values, actions) => {
    try {
      const { address: checkedAddress } = await checkAvailability({
        city: values.city,
        zipCode: values.zipCode,
        houseNumber: values.houseNumber,
        street: values.street,
      });

      const { countryCode, phoneNumber, title, ...val } = values;
      const requestObject: ILeadContact = {
        ...val,
        title: title || null,
        mobilePhone: phoneNumber ? countryCode + phoneNumber : '',
        houseSerialNumber: checkedAddress?.hausLfdnr,
        client: isNC() ? 'NC' : 'NA',
      };

      const response = await sendLeadContactForm(callbackURL, requestObject);

      if (response) {
        setView(View.Success);
      } else {
        setView(View.Error);
        actions.setSubmitting(false);
      }
    } catch (error) {
      setView(View.Error);
      actions.setSubmitting(false);
    }
  };

  switch (view) {
    case View.Loading:
      return <LoadingSpinner theme="blue" />;
    case View.Success:
      return <Success />;
    case View.OptInOrOut:
      return (
        <OptInOrOut
          mode={mode as string}
          hash={hash as string}
          successContentSlug={
            mode === Opt.in ? optInContentSlug : optOutContentSlug
          }
          errorContentSlug={errorContentSlug}
          doOptCall={mode === Opt.in ? sendOptIn : sendOptOut}
        />
      );
    default:
      return (
        <Formik
          initialValues={initialData}
          validationSchema={LeadContactSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <FormWrapper hasError={view == View.Error} />
        </Formik>
      );
  }
};

export const LeadContact: FC<LeadContactProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <LeadContactComponent {...props} />
    </div>
  );
};
