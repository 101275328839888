import {
  useEffect,
  useState,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react';

import { getObserver } from './intersectionObserver';

export const useIntersectionObserver = (
  wrapperRef: MutableRefObject<Element>,
  threshold?: number[],
  rootMargin?: string,
): { entry: IntersectionObserverEntry } => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const [isReady, setIsReady] = useState(false);
  const observer = useRef<IntersectionObserver>(null);
  const intersectionThreshold =
    threshold || [...Array(100)].map((_, i) => i * 0.01);

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setEntry(entry);
      });
    },
    [],
  );

  useEffect(() => {
    if (isReady && wrapperRef?.current) {
      if (observer.current) observer.current.disconnect();
      const newObserver = getObserver(
        null,
        observer,
        intersectionCallback,
        intersectionThreshold,
        rootMargin,
      );

      newObserver.observe(wrapperRef.current);

      return () => newObserver.disconnect();
    }
  }, [wrapperRef?.current?.children?.length, isReady]);

  useEffect(() => {
    async function checkPolyFills() {
      if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
      }
      setIsReady(true);
    }
    checkPolyFills();
  }, []);

  return {
    entry,
  };
};
