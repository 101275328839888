import {
  ChevronLink,
  Grid,
  IconAttention,
  RichText,
  Separator,
  Spacer,
  Text,
} from 'dss-ui-library';
import { AvailabilityResponseModalProps } from './types';
import { IconTextBox } from './Elements/IconTextBox';
import { replaceDynamicText } from '../../../../../utils/helpers';
import { useAvailabilityContext } from '../../../Context';
import { ModalContentWrapper } from '@ncs-frontend-monorepo/availability';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import styles from '../Result.module.scss';
import { Media } from '@ncs-frontend-monorepo/utils';
import React from 'react';
import { useOrderContext } from '../../../../OrderContext';
import { useRouter } from 'next/router';
import { ftthButtonLinkText } from '../FTTH';
import { getPortfolioUrl } from '../../../../../utils/plan';

export const ModalContentFTTH: React.FC<AvailabilityResponseModalProps> = ({
  content,
  portfolio,
  address,
  handleClose,
  handleAddressReset,
  availabilityType,
}) => {
  const { availabilityInfo, templateId } = useAvailabilityContext();
  const { maxAvailableDownload } = useOrderContext();
  const { push } = useRouter();

  const handleRedirect = () => {
    if (content?.buttonLink?.url) {
      push(content.buttonLink.url);
    } else {
      const url = getPortfolioUrl({
        portfolio: 'CLASSIC',
        templateId,
      });
      push(url);
    }
  };

  return (
    <ModalContentWrapper
      title="Verfügbare Produkte an deiner Adresse"
      address={address}
      resetAddress={handleAddressReset}
    >
      <Grid fullWidth>
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2_2">{content.headline}</Text>
            <Spacer t={1} block />
          </Grid.Column>
          <Grid.Column s={7} className={styles.infoText}>
            <Spacer r={2}>
              <IconAttention color="blue" width={24} height={24} />
            </Spacer>
            <RichText
              text={replaceDynamicText(content.modalText2, {
                plannedBandwidth:
                  availabilityInfo.objectInformation.plannedBandwidth,
                availabilityDateHalfYear:
                  availabilityInfo?.objectInformation?.availabilityDateHalfYear,
              })}
            />
          </Grid.Column>
          <Grid.Column s={5} centerS hEndS>
            <Media lessThan="s">
              <Spacer t={2} block />
            </Media>
            <ButtonLink
              btnFullwidth
              link={{ url: availabilityInfo.objectInformation?.landingpagePk }}
            >
              {ftthButtonLinkText.includes(availabilityType.key)
                ? 'Informieren & vorbestellen'
                : 'Jetzt informieren'}
            </ButtonLink>
          </Grid.Column>
        </Grid.Row>

        {content.modalText1 && (
          <>
            <Spacer t={3} b={3} block>
              <Separator height={4} color={'superlight-grey'} />
            </Spacer>

            <Grid.Column>
              <Text appearance="t2_2">Jetzt bestellbar</Text>
              <Spacer t={1} block />
            </Grid.Column>

            <Grid.Column>
              <IconTextBox
                icon="wlan"
                text={replaceDynamicText(content.modalText1, {
                  maxAvailableDownload,
                  plannedBandwidth:
                    availabilityInfo?.objectInformation?.plannedBandwidth,
                })}
                textCols={10}
              />
            </Grid.Column>
            <Grid.Row>
              <Grid.Column hEndS>
                <Spacer t={2} block />
                <ChevronLink
                  appearance="t5_2"
                  onClick={
                    portfolio === 'CLASSIC' ? handleClose : handleRedirect
                  }
                >
                  Zu den DSL/Kabel Tarifen
                </ChevronLink>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </ModalContentWrapper>
  );
};
